<template>
    <Header :title="$tc('company', 2)">
        <template v-slot:actions>
            <Button :to="{ name: 'createCompany' }">{{ $tc('create_company', 1) }}</Button>
        </template>
    </Header>

    <section class="mt-10">
        <table class="min-w-full divide-y divide-fibonaki-border">
            <thead>
                <tr>
                    <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('id', 1) }}</th>
                    <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('name', 1) }}</th>
                    <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('enterprise_number', 1) }}</th>
                    <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('email', 1) }}</th>
                    <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('phone', 1) }}</th>
                    <th scope="col">
                        <span class="sr-only">Menu</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="company in companies" :key="company">
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ company.id }}</td>
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ company.name }}</td>
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ company.enterprise_number }}</td>
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ company.email }}</td>
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ company.phone }}</td>
                    <td class="px-4 py-3 whitespace-nowrap text-sm">
                        <router-link :to="{ name: 'companyDetails', params: { company_id: company.id } }" class="text-blue-500"> Details </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
    export default {
        created() {
            this.$store.dispatch('companies/fetchCompanies');
        },
        computed: {
            companies() {
                return this.$store.getters['companies/companies'].map((company) => ({
                    id: company.id,
                    name: `${company.legal_entity_type} ${company.business_name}`,
                    enterprise_number: company.enterprise_number,
                    email: company.email,
                    phone: company.phone,
                }));
            },
        },
    };
</script>
